









































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Watch, Inject } from "vue-property-decorator";
import { TicketViewModel } from "../viewmodels/ticket-viewmodel";

@Observer
@Component({})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: TicketViewModel;
}
